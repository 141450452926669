@tailwind base;
@tailwind components;
@tailwind utilities;


/* MainPage.css */
.fade-enter {
  opacity: 0;
  transform: scale(0.9) rotate(-10deg);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1) rotate(0deg);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9) rotate(10deg);
  transition: opacity 300ms, transform 300ms;
}



.header .logo img {
  width: 130px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

ul,
li {
  list-style-type: none;
}

.section-2 img {
  max-width: 100%;
  width: 500px;
}

@media (min-width: 640px) {
  .hero {
    position: relative;
    background: url('./assets/Hero/microsoft365.jpg') no-repeat center/cover;
    height: 500px;
  }
  

  .hero-img {
    display: none;
  }

  .hero .overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .hero .overlay p.text {
    width: 300px;
  }
  .hero1 {
    position: relative;
    background: url('./assets/Hero/surfacepro8.webp') no-repeat center/cover;
    height: 500px;
  }
  .hero-img1 {
    display: none;
  }

  .hero1 .overlay1 {
    position: absolute;
    top: 20%;
    transform: translateY(-50%);
  }

  .hero1 .overlay1 p.text {
    width: 300px;
  }
  .one-drive {
    background: url('./assets/onedrive.webp') no-repeat
      center/cover;
  }
}

@media (min-width: 768px) {
  .header .menu-btn,
  .header .cart {
    display: none;
  }

  .header .logo {
    display: flex;
  }

  .header .logo ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header .logo ul li {
    margin: 0 10px;
    font-size: 14px;
  }

  .header .sign-in ul {
    display: flex;
  }

  .header .sign-in ul li {
    margin: 0 10px;
    font-size: 14px;
  }
  
  .logo-content{
     margin-left:20%
  }
  .for-business h2{
  margin-left:5rem;
  }
  header{
  margin-left:5rem;
  margin-right:95px;
  }
}

@media (min-width: 900px) {
  .hero .overlay p.text {
    width: 500px;
  }
}

@media (max-width: 768px) {
  .header .logo ul,
  .header .sign-in {
    display: none;
  }
}
